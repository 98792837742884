import React from 'react';

class App extends React.Component {
    render() {
        return <div id='app-container'>
                    <div id='title'>
                        <h1 className='center'>Real Code</h1>
                        <p className='center'>ACADEMY</p>
                    </div>
                </div>;
    }
}

export default App;
